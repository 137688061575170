import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { Link } from 'gatsby';
import {
  useTheme,
  Text,
  Divider,
  MenuItem,
  Menu,
  Box,
  Icon,
} from '@chakra-ui/core';
import { mediaQueries } from '../../helpers';
import { PaymentMethods } from '../common';

const Container = styled.div`
  width: 100%;
  background: ${props => props.colors.gray[900]};
  padding: 24px 56px;

  ${mediaQueries.laptop(`
    padding: 16px;
  `)}
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaQueries.tablet(`
    flex-direction: column;
  `)}
`;

const LinksWrapper = styled.div`
  width: 100%;
  color: ${props => props.colors.gray[200]};
`;

const RightBlock = styled.div`
  color: ${props => props.colors.gray[200]};
  width: 50%;
  ${mediaQueries.tablet(`
    width: 100%;
    margin: 16px 0;
  `)}
`;

const BillingWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const LinkBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 8px;
`;

const MailLink = styled.a`
  transition: color 200ms;

  &:hover {
    color: ${props => props.colors.blue[500]};
  }
`;

const Footer = ({ siteTitle }) => {
  const theme = useTheme();

  return (
    <footer>
      <Container colors={theme.colors}>
        <ContentWrapper>
          <LinksWrapper colors={theme.colors}>
            <Menu>
              <Link to="/le-cocoon">
                <MenuItem>Le Cocoon</MenuItem>
              </Link>
              <Link to="/le-cosy">
                <MenuItem>Le Cosy</MenuItem>
              </Link>
              <Link to="/coup-coeur">
                <MenuItem>Le Coup de Coeur</MenuItem>
              </Link>
              <Link to="/guide">
                <MenuItem>Notre guide</MenuItem>
              </Link>
              <Link to="/legal">
                <MenuItem>Mentions légales</MenuItem>
              </Link>
            </Menu>
          </LinksWrapper>
          <RightBlock colors={theme.colors}>
            <LinkBlock>
              <Box as="span" mx="8px" color="gray.500" fontSize="sm">
                Nous contacter:
              </Box>{' '}
              <MailLink
                name="Contacter nous"
                colors={theme.colors}
                href="mailto:lecocoondesarah@yahoo.com?subject=Demande d'informations depuis notre site"
              >
                lecocoondesarah@yahoo.com <Icon name="chat" mx="2px" />
              </MailLink>
            </LinkBlock>
            <BillingWrapper>
              <PaymentMethods />
            </BillingWrapper>
          </RightBlock>
        </ContentWrapper>
        <Divider />
        <Text color={theme.colors.gray[50]}>
          {`${siteTitle} © ${new Date().getFullYear()}`}
        </Text>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
