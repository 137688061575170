import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, CSSReset, ColorModeProvider } from '@chakra-ui/core';

import '../../styles/layout.css';

const Main = ({ children }) => {
  return (
    <ThemeProvider>
      <ColorModeProvider>
        <CSSReset />
        {children}
      </ColorModeProvider>
    </ThemeProvider>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
