import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GoHome } from 'react-icons/go';
import { IoMdBook } from 'react-icons/io';
import {
  useTheme,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
} from '@chakra-ui/core';
import Logo from './logo';
import { mediaQueries } from '../../helpers';

const NavBarWrapper = styled.div`
  margin-top: ${props => (props.hasBanner ? '110px' : '0')};
  margin-bottom: 64px;
  width: 100%;
  z-index: 10;
  position: fixed;
  padding: 8px 16px;
  background: ${props =>
    !props.transparent ? props.theme.colors.gray[50] : 'transparent'};
  transition: 100ms background, 200ms box-shadow;
  box-shadow: ${props =>
    props.transparent ? 'none' : '-1px 7px 19px -9px rgba(23,25,35,0.20)'};
`;

const NavBarContainer = styled.div`
  margin: 0px auto;
  max-width: 1300px;
`;

const LinkContainer = styled.div`
  padding: 8px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinkLogo = styled(Link)`
  transition: 200ms width;
  width: ${props => (props.transparent ? '160px' : '100px')};

  ${mediaQueries.laptop(`
    width: ${props => (props.transparent ? '220px' : '140px')};
  `)}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  transition: 200ms display;
  opacity: ${props => (props.isTransparent ? 0 : 100)};
`;

const NavBar = ({ transparent }) => {
  const theme = useTheme();

  return (
    <>
      <NavBarWrapper theme={theme} transparent={transparent}>
        <NavBarContainer>
          <LinkContainer>
            <LinkLogo name="Page d'accueil" transparent={transparent} to="/">
              <Logo />
            </LinkLogo>
            <ButtonsWrapper isTransparent={transparent}>
              <Menu>
                <MenuButton
                  px={4}
                  py={2}
                  transition="all 0.2s"
                  rounded="md"
                  borderWidth="1px"
                  border="none"
                  _hover={{ bg: 'gray.100' }}
                  _expanded={{ bg: 'gray.100' }}
                  _focus={{ outline: 0 }}
                  variant="link"
                  variantColor="gray900"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box as={GoHome} marginRight="8px" />
                  Appartements
                </MenuButton>
                <MenuList>
                  <Link to="/le-cocoon">
                    <MenuItem>Le Cocoon</MenuItem>
                  </Link>
                  <Link to="/le-cosy">
                    <MenuItem>Le Cosy</MenuItem>
                  </Link>
                  <Link to="/coup-coeur">
                    <MenuItem>Le Coup de Coeur</MenuItem>
                  </Link>
                </MenuList>
              </Menu>
              <Menu>
                <Link to="/guide">
                  <MenuButton
                    px={4}
                    py={2}
                    transition="all 0.2s"
                    rounded="md"
                    borderWidth="1px"
                    border="none"
                    _hover={{ bg: 'gray.100' }}
                    _expanded={{ bg: 'gray.100' }}
                    _focus={{ outline: 0 }}
                    variant="link"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box as={IoMdBook} marginRight="8px" />
                    Guide
                  </MenuButton>{' '}
                  {/* Keep this even empty to have the correct focus  */}
                  <MenuList display="none">
                    <MenuItem></MenuItem>
                  </MenuList>
                </Link>
              </Menu>
            </ButtonsWrapper>
          </LinkContainer>
        </NavBarContainer>
      </NavBarWrapper>
    </>
  );
};

NavBar.propTypes = {
  transparent: PropTypes.bool.isRequired,
};

export default NavBar;
