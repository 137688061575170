const size = {
  mobile: 375,
  tablet: 768,
  laptop: 1024,
  desktop: 1440,
};

export const devices = {
  mobile: `(max-width: ${size.mobile - 1}px)`,
  tablet: `(max-width: ${size.tablet - 1}px)`,
  laptop: `(max-width: ${size.laptop - 1}px)`,
  desktop: `(max-width: ${size.desktop - 1}px)`,
};

export default devices;
