import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Box, useTheme, Badge, Icon, Text, Image } from '@chakra-ui/core';
import { mediaQueries } from '../../helpers';

const Wrapper = styled.div`
  max-width: 400px;
  border-radius: 4px;
  overflow: hidden;
  align-self: stretch;
  margin: 32px;
  box-shadow: 2px 3px 5px rgba(28, 26, 28, 0.2);

  ${mediaQueries.mobile(`
    margin: 8px;
  `)}
`;

const HouseCard = props => {
  const { property } = props;
  const [hovered, setHover] = React.useState(false);
  const theme = useTheme();

  return (
    <Link
      to={`/${property.slug}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Wrapper>
        <Box width="100%" height="100%" position="relative">
          <Image
            src={property.imageUrl}
            alt={property.imageAlt}
            backgroundColor={theme.colors.gray[700]}
            width="100%"
            height="250px"
            minWidth="375px"
          />
          {hovered ? (
            <Box
              position="absolute"
              backgroundColor="rgba(0,0,0,0.40)"
              top="0"
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color={theme.colors.gray[200]}
              padding="8px"
              zIndex="4"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-betweenm"
              >
                <Text fontSize="lg" marginLeft="24px">
                  Voir l'appartement
                </Text>
                <Icon name="arrow-forward" size="24px" />
              </Box>
            </Box>
          ) : null}
        </Box>

        <Box p="6">
          <Box d="flex" alignItems="baseline">
            <Badge rounded="full" px="2" variantColor="gray">
              {property.locality}
            </Badge>
            <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {property.beds} lits &bull; {property.baths} sdb
            </Box>
          </Box>

          <Box pl="12px" pt="8px">
            <Box
              mt="1"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
            >
              {property.title} -{' '}
              <Box as="span" ml="2" color="gray.500" fontSize="sm">
                {property.people} personnes
              </Box>
            </Box>

            <Box paddingTop="8px">
              <Box as="span" color="gray.600" fontSize="sm">
                {'A partir de '}
              </Box>
              {property.formattedPrice}
              <Box as="span" color="gray.600" fontSize="sm">
                {' / nuit'}
              </Box>
            </Box>

            <Box d="flex" mt="2" alignItems="center">
              {Array(5)
                .fill('')
                .map((_, i) => (
                  <Icon
                    name="star"
                    size="16px"
                    color={i < property.rating ? 'blue.700' : 'gray.300'}
                    key={i}
                  />
                ))}
              <Box as="span" ml="2" color="gray.600" fontSize="sm">
                Plus de <b>{property.reviewCount}</b> avis
              </Box>
            </Box>
          </Box>
        </Box>
      </Wrapper>
    </Link>
  );
};

export default HouseCard;
