import devices from './devices';

const laptop = content => `
    @media ${devices.laptop} {  // -> "@media (min-width: 1024px)"
      ${content}
    }
`;

const desktop = content => `
    @media ${devices.desktop} {  // -> "@media (min-width: 1024px)"
      ${content}
    }
`;

const tablet = content => `
    @media ${devices.tablet} {  // -> "@media (min-width: 1024px)"
      ${content}
    }
`;

const mobile = content => `
    @media ${devices.tablet} {  // -> "@media (min-width: 1024px)"
      ${content}
    }
`;

// special for appartements sidebar
const largerThanDesktop = content => `
    @media (min-width: 1440px) {
      ${content}
    }
`;

const mediaQueries = {
  laptop,
  desktop,
  tablet,
  mobile,
  largerThanDesktop,
};

export default mediaQueries;
