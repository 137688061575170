import React from 'react';
import styled from 'styled-components';

const BillingItem = styled.div`
  background-image: url(${props => props.url});
  width: 72px;
  height: 72px;
`;

const LOGO_URLS = [
  'https://res.cloudinary.com/dzba82asr/image/upload/c_thumb,w_70,g_face/v1587386891/lecoccondesarah/cb-logo-eu-16jun17_lvyhet.png',
  'https://res.cloudinary.com/dzba82asr/image/upload/c_thumb,w_70,g_face/v1587386422/lecoccondesarah/logo-esp_C3_A8ce_lvzvsz.png',
  'https://res.cloudinary.com/dzba82asr/image/upload/c_thumb,w_70,g_face/v1587386422/lecoccondesarah/macaron_cheque_vacances_1_kqd4se.png',
];

const PaymentMethods = () => {
  return LOGO_URLS.map((url, index) => <BillingItem url={url} key={index} />);
};

export default PaymentMethods;
