import React from 'react';
import NavBar from './navbar';

const Header = ({ children }) => {
  const [isTransparent, setTransparent] = React.useState(true);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  React.useEffect(() => {
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    if (scrollY > 100) {
      setTransparent(false);
    } else {
      setTransparent(true);
    }
  };

  return (
    <header>
      <NavBar transparent={isTransparent} />
      {children}
    </header>
  );
};

export default Header;
