import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, useTheme, useColorMode } from '@chakra-ui/core';
import { ThemeProvider } from 'styled-components';

import Header from './header';
import Footer from './footer';
import Main from './main';

const Layout = ({ children, headerChildren }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const theme = useTheme();
  const { title } = data.site.siteMetadata;
  const { colorMode, toggleColorMode } = useColorMode();

  if (colorMode === 'dark') {
    toggleColorMode('light');
  }

  return (
    <ThemeProvider
      theme={{
        reactDatepicker: {
          dateRangeZIndex: 10000,
          dateRangeDatepickerWrapperTop: '50px',
        },
      }}
    >
      <Main>
        <Box w="100%" minH="100vh">
          <Header siteTitle={title}>{headerChildren || null}</Header>
          <Box m="0 auto" p="56px 16px" pt={0} maxW={1440}>
            <Box pt="48px">{children}</Box>
          </Box>
          <Footer siteTitle={title} />
        </Box>
      </Main>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerChildren: PropTypes.node,
};

export default Layout;
